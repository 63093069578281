.databaseMenu {
    position: static;
    top: -412px  !important;
    left: 253px !important;
}
.addQueryButton{
    border: none;
    border-radius: 10px;
    width: 40px;
    height: 30px;
    cursor: pointer;
    background-color:rgb(132, 181, 255);
    transition: 0.2s;
    color: white;
    font-size: 18px;
}

.addQueryButton:hover{
    background-color:rgb(197, 220, 255);
}

.infoDates{
    margin-right: 35px;
    margin-bottom: 20px;
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
}